body.about.about-bangkok,
body.about.about-singapore,
body.about.about-hong-kong,
body.about.about-ho-chi-minh,
body.about.about-da-nang {
  .hero {
    max-width: rem(335px);
    margin: auto;
    padding-bottom: rem(100px);
    word-break: break-word;

    @include media-breakpoint-up('lg') {
      max-width: rem(1085px);

      padding-top: rem(112px);
      padding-bottom: rem(218px);
    }
  }

  .hero__lead-text {
    font-size: rem(36px);
    line-height: 125%;

    @include media-breakpoint-up('lg') {
      font-size: rem(66px);
    }
  }

  .content-section {
    padding-top: rem(120px);
    padding-bottom: rem(120px);
  }

  .content-section .btn {
    width: auto;
  }
}

body.about .content-section {
  &__header-title {
    margin-bottom: $grid-gutter-width * 0.75;

    font-size: map-get($font-sizes, 'xxs');
    text-transform: uppercase;

    @include media-breakpoint-up('lg') {
      margin-bottom: $grid-gutter-width;

      font-size: map-get($font-sizes, 'sm');
    }
  }
}

body.about .content-section--about-values .list-topic {
  margin-top: $grid-gutter-width * 0.75;

  @include media-breakpoint-up('lg') {
    margin-top: $grid-gutter-width;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: rem(50px);

      @include media-breakpoint-up('lg') {
        margin-bottom: 0;
      }
    }
  }
}

body.about .content-section--about-culture {
  background: {
    image: url(map-get($sprites, 'shape-6'));
    size: auto 200%;
    position: right center;
  }

  @include media-breakpoint-up('lg') {
    background-size: auto;
  }
}

body.about .content-section--about-growth {
  background-image: url(map-get($sprites, 'shape-3'));

  @include media-breakpoint-up('lg') {
    background-position: calc(100% + 200px) 140px;
  }
}

body.about .content-section--list-location {
  background: {
    image: url(map-get($sprites, 'shape-7'));
    size: 200% 225%;
    position: center;
  }

  @include media-breakpoint-up('lg') {
    background-size: 100% 225%;
  }

  .content-section__text {
    margin-right: 0;
  }

  .list-location {
    margin-top: rem(44px);

    @include media-breakpoint-up('lg') {
      margin-top: 5rem;
    }
  }
}

body.about .content-section--about-join {
  color: $base-font-color;

  background: {
    image: url(map-get($sprites, 'shape-8'));
    position: 115px -150px;
  }

  @include media-breakpoint-up('lg') {
    background: {
      position: calc(100% - 100px) center;
      size: 30%;
    }
  }

  .content-section__text {
    @include media-breakpoint-up('lg') {
      margin-right: calc(100% - 500px);
    }
  }

  .btn {
    @include media-breakpoint-up('lg') {
      padding-right: rem(90px);
      padding-left: rem(90px);
    }
  }
}

body.about .content-section--list-customer {
  text-align: center;

  &.content-section {
    padding-top: rem(60px);
    padding-bottom: rem(60px);

    @include media-breakpoint-up('lg') {
      padding-top: rem(120px);
      padding-bottom: rem(120px);
    }
  }

  .content-section__header-title {
    margin-bottom: rem(24px);

    @include media-breakpoint-up('lg') {
      margin-bottom: rem(20px);
      letter-spacing: rem(4px);
    }
  }

  .list-customer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  
    @include responsive-customer-section();
  }
}

body.about .content-section--list-our-service {
  background-image: url(map-get($sprites, 'shape-1'));
  background-position: left bottom rem(-140px);
  background-size: 150%;

  @include media-breakpoint-up('sm') {
    background-position: left bottom rem(-220px);
    background-size: 120%;
  }

  @include media-breakpoint-up('lg') {
    background-position: left bottom rem(-330px);
    background-size: auto;
  }

  .content-section__text {
    margin-bottom: rem(44px);

    @include media-breakpoint-up('lg') {
      margin-right: rem(60px);
      margin-bottom: 0;
    }

    @include media-breakpoint-up('xl') {
      margin-right: rem(144px);
    }
  }
}

body.about .content-section--location-map {
  position: relative;

  .content-section__text {
    @include media-breakpoint-up('lg') {
      margin-right: rem(33px);
    }
  }
}
