.contact-location {
  &__legal-name {
    font-size: map-get($font-sizes, 'xxl');
    font-weight: map-get($font-weight, 'bolder');
    line-height: rem(36px);

    margin-bottom: rem(8px);
  }

  &__address,
  &__contact-link {
    margin-bottom: 2rem;
  }

  &__link {
    display: inline-flex;
    align-items: center;

    &:after {
      display: none;
    }
  }

  &__link-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: rem(35px);
    height: rem(35px);

    color: hsl(0, 0%, 100%);
    background: var(--color-brand-red-ribbon);

    margin-right: 1rem;
  }

  &__link-text {
    @include multiple-line-underline();

    font-size: map-get($font-sizes, 'default');
    font-weight: map-get($font-weight, 'bolder');

    color: var(--color-brand-valhalla);
  }

  &__other-location-link {
    @include multiple-line-underline();
  }

  &__other-location-link {
    position: relative;

    font-weight: map-get($font-weight, 'bolder');

    margin-right: rem(4px);

    &:not(:last-child):before {
      content: ',';
      color: var(--color-brand-valhalla);
      font-weight: normal;

      position: absolute;
      right: rem(-6px);
    }
  }

  &__map {
    position: relative;
    bottom: rem(-120px);
    left: -($grid-gutter-width / 2);

    height: rem(290px);
    width: 100vw;

    @include media-breakpoint-up('lg') {
      position: absolute;
      top: 0;
      right: -$grid-gutter-width;
      bottom: initial;
      left: initial;

      width: calc(50% + #{$grid-gutter-width});
      height: 100%;
    }
  }
}
