.list-customer {
  &__item {
    @include responsive-client-logos();
  }
}

.list-customer__logo {
  &--grayscale {
    @include grayscale-logo();
  }
}
