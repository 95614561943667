.contact-directory {
  display: grid;
  grid-template-rows: auto rem(52px);
  grid-row-gap: 1rem;

  position: relative;
  height: 100%;
  padding: rem(32px 32px 48px);

  background: hsl(0, 0%, 100%);

  @include media-breakpoint-up('lg') {
    grid-template-rows: auto rem(84px);
    grid-row-gap: rem(56px);

    padding: rem(70px 70px 56px);
  }
}

.contact-directory__content-section {
  display: grid;
  grid-template-rows: 1rem auto;
  grid-row-gap: rem(12px);

  // Target very small devices like iPhone 5
  @include media-portrait() {
    @media (max-height: 640px) {
      grid-row-gap: rem(6px);
    }
  }

  @include media-breakpoint-up('lg') {
    grid-row-gap: 2rem;
  }
}

.contact-directory__content-section--location {
  @include media-breakpoint-down('md') {
    grid-template-rows: auto;
  }

  header {
    @include media-breakpoint-down('md') {
      display: none;
    }
  }
}

.contact-directory__location {
  &-item {
    display: flex;
    align-items: center;
  }

  &-item:not(:last-child) {
    margin-bottom: 1rem;

    // Target very small devices like iPhone 5
    @include media-portrait() {
      @media (max-height: 640px) {
        margin-bottom: 0.5rem;
      }
    }

    @include media-breakpoint-up('lg') {
      margin-bottom: rem(28px);
    }
  }

  &-pin {
    width: rem(20px);
    height: rem(20px);

    margin-right: rem(8px);
    margin-bottom: rem(4px);

    @include media-breakpoint-up('lg') {
      width: rem(24px);
      height: rem(24px);

      margin-bottom: rem(6px);
    }
  }

  &-link {
    color: var(--color-brand-woodsmoke);
    font-size: map-get($font-sizes, 'default');
    font-weight: map-get($font-weight, 'bolder');

    // Target very small devices like iPhone 5
    @include media-portrait() {
      @media (max-height: 640px) {
        font-size: map-get($font-sizes, 'sm');
      }
    }

    @include media-breakpoint-up('lg') {
      font-size: map-get($font-sizes, 'xxl');
    }

    &:hover {
      color: inherit;
    }
  }
}

.contact-directory__title {
  font-size: map-get($font-sizes, 'xs');
  font-weight: map-get($font-weight, 'bolder');

  color: var(--color-brand-ghost);
  text-transform: uppercase;
  letter-spacing: 1px;

  margin-bottom: 0;

  // Target very small devices like iPhone 5
  @include media-portrait() {
    @media (max-height: 640px) {
      font-size: map-get($font-sizes, 'xxs');
    }
  }
}

.contact-directory__contact-link {
  font-size: map-get($font-sizes, 'default');
  font-weight: map-get($font-weight, 'bolder');

  // Target very small devices like iPhone 5
  @include media-portrait() {
    @media (max-height: 640px) {
      font-size: map-get($font-sizes, 'sm');
    }
  }

  @include media-breakpoint-up('lg') {
    font-size: map-get($font-sizes, 'xxl');
  }
}
