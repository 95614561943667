.tag {
  padding: rem(12px) rem(14px);
  line-height: 1;

  color: hsl(0, 0%, 100%);
  font-size: map-get($font-sizes, 'xs');
  text-transform: uppercase;

  background: hsla(0, 0%, 100%, 0.3);
}
