@import 'variables';

// Vendor
@import '../vendor/stylesheets/bootstrap/bootstrap';

// Application

@import 'functions/asset-url';
@import 'functions/image-url';
@import 'functions/sizing';

@import 'mixins/animation';
@import 'mixins/button';
@import 'mixins/client-logos';
@import 'mixins/grid';
@import 'mixins/responsive';
@import 'mixins/shape-sprite';
@import 'mixins/text';
@import 'mixins/underline';

@import 'base/reboot';
@import 'base/root';
@import 'base/fonts';
@import 'base/forms';
@import 'base/typography';

@import 'layouts/default';

@import 'components/alert';
@import 'components/btn';
@import 'components/app-footer';
@import 'components/icon';
@import 'components/icon-hamburger';
@import 'components/link';
@import 'components/list';
@import 'components/list-social-platform';
@import 'components/logo';

@import 'components/website/app-banner';
@import 'components/website/app-navigation';
@import 'components/website/carousel';
@import 'components/website/carousel-portfolio';
@import 'components/website/contact-directory';
@import 'components/website/contact-location';
@import 'components/website/content-section';
@import 'components/website/customer-section';
@import 'components/website/hero';
@import 'components/website/list-tag';
@import 'components/website/list-topic';
@import 'components/website/list-customer';
@import 'components/website/list-location';
@import 'components/website/list-service';
@import 'components/website/map';
@import 'components/website/navigation-tab';
@import 'components/website/tag';
@import 'components/website/form-contact';
@import 'components/website/testimonial';

@import 'screens/website/about';
@import 'screens/website/home';
@import 'screens/website/services';
@import 'screens/website/work';
@import 'screens/website/contact-us';
