.app-banner {
  &__text--xs {
    display: initial;

    @include media-breakpoint-up('md') {
      display: none;
    }
  }

  &__text--md {
    display: none;

    @include media-breakpoint-up('md') {
      display: initial;
    }
  }
}