.layout-default body {
  background: var(--color-brand-valhalla);
}

.layout-default .app-header,
.layout-default .app-content,
.layout-default .app-footer {
  @include make-content-container();
}

.layout-default .app-header {
  padding-top: rem(20px);
  position: relative;

  @include media-breakpoint-up('lg') {
    padding-top: rem(40px);
  }

  // Random shape background

  &:before {
    position: absolute;
    z-index: map-get($zIndex, 'behind');
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';
    background-size: cover;
    background-repeat: no-repeat;
  }

  @for $shape from 1 to 9 {
    &--shape-#{$shape}:before {
      @include fade-in();

      background-image: url(map-get($sprites, 'shape-header-#{$shape}'));
    }
  }
}

.layout-default .app-content {
  min-height: rem(300px);

  background: hsl(0, 0, 100%);

  @include media-breakpoint-up('lg') {
    min-height: rem(400px);
  }
}
