.hero {
  padding: rem(50px) 0;

  color: hsl(0, 0%, 100%);
  text-align: center;

  @include media-breakpoint-up('lg') {
    padding: rem(80px) 0;
  }

  &__lead-text {
    line-height: 1;
    font-size: rem(87px);
    font-weight: bold;

    @include media-breakpoint-up('lg') {
      font-size: map-get($heading-sizes, 'h1');
    }
  }

  &__secondary-text {
    margin-top: 1rem;
    margin-bottom: 2rem;

    @include media-breakpoint-up('lg') {
      margin-top: 2rem;
      margin-bottom: 2.5rem;
    }
  }

  &__action {
    display: block;

    @include media-breakpoint-up('lg') {
      display: inline-block;
    }
  }
}
