body.services .content-section {
  &__header h6,
  &__header-title {
    margin-bottom: $grid-gutter-width * 0.75;

    font-size: map-get($font-sizes, 'xxs');
    text-transform: uppercase;

    @include media-breakpoint-up('lg') {
      margin-bottom: $grid-gutter-width;

      font-size: map-get($font-sizes, 'sm');
    }
  }

  &__illustration {
    overflow: hidden;
    margin-bottom: 0;

    margin-right: -#{$grid-gutter-width/2};
    margin-left: -#{$grid-gutter-width/2};

    @include media-breakpoint-up('lg') {
      margin-right: -#{$grid-gutter-width};
      margin-left: 0;
    }
  }
}

body.services .content-section--service-web,
body.services .content-section--service-mobile,
body.services .content-section--service-apple-os,
body.services .content-section--service-hybrid-app {
  @include media-breakpoint-up('lg') {
    display: flex;
  }

  .list {
    margin-top: 2rem;
  }

  .content-section__illustration img {
    margin-top: $grid-gutter-width;
    margin-left: -50%;

    @include media-landscape() {
      margin-left: 0;
    }

    @include media-breakpoint-up('md') {
      margin-left: 0;
    }
  }
}

body.services .content-section--service-design,
body.services .content-section--service-next {
  .list {
    margin-top: 2rem;
  }
}

body.services .content-section--service-next {
  .list--link .list__item-link {
    color: #fff;
  }
}

body.services .content-section--service-web {
  color: $base-font-color;
  background-color: map-get($color-gray, 'lightest');

  @include media-breakpoint-down('md') {
    padding-bottom: 0;
  }

  .content-section__text {
    @include media-breakpoint-up('lg') {
      flex: calc(100% - 375px) 0 0;
    }
  }

  .content-section__illustration {
    height: rem(375px);

    @include media-breakpoint-up('lg') {
      margin-top: rem(-30px);
      margin-bottom: rem(-30px);
      height: auto;
    }
  }

  .content-section__text > p {
    @include media-breakpoint-up('lg') {
      padding-right: 20%;
    }
  }

  .list-topic {
    @include media-breakpoint-up('lg') {
      margin-right: 0;
    }

    @include media-breakpoint-up('xxl') {
      margin-right: 10px;
    }
  }
}

body.services .content-section--service-mobile {
  padding-bottom: 0;

  .content-section__text {
    @include media-breakpoint-up('lg') {
      flex: calc(100% - 500px) 0 0;
    }

    @include media-breakpoint-up('xxl') {
      flex: calc(100% - 600px) 0 0;
    }
  }

  .list-topic {
    @include media-breakpoint-up('lg') {
      padding-bottom: rem(100px);
    }
  }

  .content-section__illustration {
    @include media-breakpoint-up('lg') {
      display: flex;
      align-items: flex-end;
    }

    img {
      margin-bottom: rem(-60px);

      @include media-breakpoint-up('lg') {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
}

body.services .content-section--primary,
body.services .content-section--secondary {
  color: $base-font-color;
}

body.services .content-section--primary {
  background-color: map-get($color-gray, 'lightest');
}

body.services .content-section--secondary {
  background-color: transparent;
}

body.services .content-section--service-design,
body.services .content-section--service-community {
  background-image: url(map-get($sprites, 'shape-3'));

  @include media-breakpoint-up('lg') {
    background-position: calc(100% + 200px) 140px;
  }
}

body.services .content-section--service-next,
body.services .content-section--service-contact {
  background-image: url(map-get($sprites, 'shape-4'));
  background-size: cover;
}

$section-backgrounds: (
  rapid-development: image-url('pages/services/logo-rails-translucent.png'),
  ror-expert: image-url('pages/services/logo-ruby-translucent.png'),
  highly-efficient: image-url('pages/services/logo-go-translucent.png'),
  designed-google: image-url('pages/services/logo-gopher-translucent.png'),
  power-erlang: image-url('pages/services/logo-elixir-translucent.png'),
  elixir-phoenix: image-url('pages/services/logo-phoenix-translucent.png'),
  native-swift: image-url('pages/services/logo-swift-translucent.png'),
  native-kotlin: image-url('pages/services/logo-kotlin-translucent.png'),
  android-expert: image-url('pages/services/logo-android-translucent.png'),
  cross-platform: image-url('pages/services/logo-flutter-translucent.png')
);

@each $section-background, $value in $section-backgrounds {
  body.services .content-section--service-#{$section-background} {
    background-image: $value;

    @include media-breakpoint-up('lg') {
      background-image: none;
    }
  }
}

body.services.services-ruby-on-rails-development,
body.services.services-golang-development,
body.services.services-ios-app-development,
body.services.services-android-app-development,
body.services.services-cross-platform-app-development,
body.services.services-e-commerce-development,
body.services-elixir-development {
  .hero {
    max-width: rem(335px);
    margin: auto;
    word-break: break-word;

    @include media-breakpoint-up('lg') {
      max-width: rem(752px);
    }
  }

  .hero__lead-text {
    font-size: rem(36px);
    line-height: 125%;

    margin-bottom: 0;

    @include media-breakpoint-up('lg') {
      font-size: rem(109px);
    }
  }

  .content-section {
    padding-top: rem(120px);
    padding-bottom: rem(120px);
  }

  .content-section .btn {
    width: auto;
  }
}

body.services.services-ios-app-development {
  .content-section--service-apple-os {
    padding-bottom: rem(60px);

    @include media-breakpoint-up('lg') {
      padding-bottom: rem(100px);
    }

    .content-section__illustration {
      overflow: visible;
      text-align: right;
    }

    .content-section__illustration img {
      margin-left: 0;

      width: rem(327px);
      height: rem(274px);

      @include media-breakpoint-up('md') {
        width: rem(526px);
        height: rem(440px);
      }
    }
  }
}

body.services.services-cross-platform-app-development {
  .hero {
    @include media-breakpoint-up('lg') {
      max-width: rem(1152px);
    }
  }

  .content-section--service-hybrid-app {
    padding-bottom: 0;

    .content-section__illustration {
      overflow: visible;
      text-align: right;
    }

    .content-section__illustration img {
      margin-left: 0;

      width: rem(343px);
      height: rem(222px);

      @include media-breakpoint-up('md') {
        width: rem(573px);
        height: rem(421px);
      }
    }
  }
}

body.services.services-e-commerce-development {
  .content-section--service-e-commerce-expert {
    background-image: url(map-get($sprites, 'shape-1'));
    background-position: 0px 230px;

    @include media-breakpoint-up('lg') {
      background-position: calc(100% + 610px) 210px;
    }
  }

  .content-section--service-e-commerce-b2b-b2c {
    background: {
      image: url(map-get($sprites, 'shape-7'));
      size: 200% 225%;
      position: center;
    }

    @include media-breakpoint-up('lg') {
      background-size: 100% 225%;
    }
  }
}
