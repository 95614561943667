.list-service {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;

  @include media-breakpoint-up('sm') {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: rem(60px);
  }

  &__icon {
    margin-bottom: rem(24px);
  }

  &__title {
    font-size: map-get($font-sizes, 'xxl');
    line-height: rem(36px);

    margin-bottom: rem(8px);
  }

  &__description {
    font-size: map-get($font-sizes, 'default');
  }
}
