.map {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  // Apply the primary colours of maps for a blend-in effect
  background-color: hsl(0, 0%, 95%);

  // Static maps redirects the search result page on Google map
  cursor: pointer;
}