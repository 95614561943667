body.contact-us .hero {
  &__lead-text {
    font-size: map-get($heading-sizes, 'h2');

    @include media-breakpoint-up('sm') {
      font-size: map-get($heading-sizes, 'h2');
    }
  }
}

body.contact-us .app-content {
  background: map-get($color-gray, 'lightest');
  padding: 0;

  @include media-breakpoint-up('sm') {
    padding: 5% 20%;
  }

  @include media-breakpoint-up('md') {
    padding: 5% 24%;
  }
}

body.contact-us .content-section--form-contact {
  background: #fff;
  padding: 2rem;

  @include media-breakpoint-up('sm'){
    padding: 9% 9.75%;
  }
}
