.list-location {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: rem(44px);

  @include media-breakpoint-up('sm') {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
  }

  @include media-breakpoint-up('lg') {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 5rem;
  }

  &__title {
    font-size: map-get($font-sizes, 'xxl');
    font-weight: map-get($font-weight, 'bolder');

    margin-bottom: rem(8px);

    @include media-breakpoint-up('lg') {
      font-size: map-get($font-sizes, 'xl');

      margin-bottom: 1rem;
    }
  }

  &__link {
    @include multiple-line-underline();

    color: hsl(0, 0%, 100%);

    &:hover {
      color: inherit;
    }
  }

  &__address {
    font-size: map-get($font-sizes, 'default');
  }
}
