.carousel-portfolio {
  $height-xs: rem(600px);
  $height-lg: rem(750px);

  &__slide  {
    height: $height-xs;
    overflow: hidden;

    transition: transform .6s ease-in-out;

    @include media-breakpoint-up('lg') {
      height: $height-lg;
    }
  }

  &__project {
    padding: $grid-gutter-width/2 0 $grid-gutter-width/2 $grid-gutter-width/2;
    height: inherit;

    overflow: hidden;

    @include media-breakpoint-up('lg') {
      padding: $grid-gutter-width 0 $grid-gutter-width $grid-gutter-width;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: initial;
      left: 0;

      border-width: $height-xs;
      border-style: solid;
      border-color: transparent;

      @include media-breakpoint-up('lg') {
        display: block;

        border-width: $height-lg;
      }
    }

    &--light {
      &:before {
        border-left-color: hsla(0, 0%, 100%, 0.06);
      }
    }

    &--dark {
      &:before {
        border-left-color: hsla(0, 0%, 0%, 0.05);
      }
    }
  }

  // Inner div required no to mess with the display value
  // of the slide required for animation

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;
    z-index: map-get($zIndex, 'default');

    height: 100%;

    @include media-breakpoint-up('lg') {
      flex-direction: row;
    }
  }

  &__project-info {
    @include media-breakpoint-up('lg') {
      flex: 0 0 35%;

      padding-left: rem(60px);
      padding-right: rem(30px);
      margin-top: rem(100px);
    }

    .list-tag {
      margin-top: 1rem;

      @include media-breakpoint-up('lg') {
        margin-top: rem(40px);
      }
    }
  }

  &__project-illustration {
    // Negative margin required to push left the image with drop shadow
    margin: rem(32px) 0 0 rem(-5px);

    user-select: none;

    @include media-landscape() {
      text-align: center;
    }

    @include media-breakpoint-up('lg') {
      display: flex;
      align-items: center;
      margin: 0;

      text-align: right;
    }
  }
}

// Variations specific for each project type

.carousel-portfolio__project--web {
  .carousel-portfolio__project-illustration > img {
    @include media-portrait() {
      max-height: rem(330px);
    }

    @include media-breakpoint-up('lg') {
      max-height: initial;
    }
  }
}

.carousel-portfolio__project--mobile {
  @include media-breakpoint-up('lg') {
    padding-right: $grid-gutter-width;
  }

  .carousel-portfolio__project-illustration {
    @include media-breakpoint-up('lg') {
      padding-right: rem(60px);
    }
  }

  .carousel-portfolio__project-illustration > img {
    max-height: rem(370px);

    @include media-breakpoint-up('lg') {
      max-width: 100%;
      max-height: initial;
    }
  }
}
