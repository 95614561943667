body.home .hero {
  // Enlarge hero text
  &__lead-text {
    font-size: rem(109px);

    @include media-breakpoint-up('lg') {
      font-size: map-get($heading-sizes, 'h1');
    }
  }

  &__secondary-text {
    margin-top: 0;
  }
}

body.home .app-content {
  background: none;

  .content-section {
    padding-top: rem(120px);
    padding-bottom: rem(120px);
    min-height: rem(500px);

    @include media-breakpoint-up('lg') {
      padding-top: rem(200px);
      padding-bottom: rem(200px);
      min-height: rem(740px);
    }
  }
}

body.home .content-section--about {
  background-image: url(map-get($sprites, 'shape-1'));
  background-position: 220px 100px;

  @include media-breakpoint-up('lg') {
    background-position: calc(100% + 750px) 140px;
  }
}

body.home .content-section--service {
  background-image: url(map-get($sprites, 'shape-3'));

  @include media-breakpoint-up('lg') {
    background-position: calc(100% + 200px) 140px;
  }
}

body.home .content-section--contact {
  background-image: url(map-get($sprites, 'shape-4'));
  background-size: cover;

  .content-section__text {
    @include media-breakpoint-up('lg') {
      margin-right: calc(100% - 700px);
    }
  }
}
