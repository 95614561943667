.content-section--testimonial {
  .content-section__text {
    margin-right: 0;

    font-weight: map-get($font-weight, 'bold');
    font-size: map-get($font-sizes, 'xxl');

    @include media-breakpoint-up('md') {
      font-size: map-get($heading-sizes, 'h5');
    }
  }

  .content-section__text p:before,
  .content-section__text p:after {
    content: "\""
  }

  .content-section__author {
    display: flex;
    align-items: center;
    margin-top: rem(30px);
  }

  .content-section__author figure {
    margin-bottom: 0;
  }

  .content-section__author-info {
    font-size: map-get($font-sizes, 'default');
    margin-left: rem(16px);
  }

  .content-section__author-name {
    font-weight: map-get($font-weight, 'bold');
  }
}
