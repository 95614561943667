body.work .content-section--work-intro {
  .content-section__text {
    @include media-breakpoint-up('lg') {
      margin-right: calc(100% - 950px);
    }

    @include media-breakpoint-up('xxl') {
      margin-right: calc(100% - 950px);
    }
  }
}

body.work .content-section--work-portfolio {
  padding: 0;
}

body.work .content-section--work-project-inquiry {
  h2 {
    position: relative;
  }

  // Triangle shape

  h2:before {
    content: '';

    position: absolute;
    top: 22px;
    left: ($grid-gutter-width / -2);
    z-index: map-get($zIndex, 'default');

    border-width: 20px;
    border-style: solid;
    border-color: transparent;
    border-left-color: var(--color-brand-red-ribbon);

    @include media-breakpoint-up('lg') {
      left: -#{$grid-gutter-width};
      top: 45px;

      border-width: 40px;
    }
  }
}

