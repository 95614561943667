.navigation-tab {
  // Remove default underline under links
  &__link:after {
    display: none;
  }

  &__link:not(.navigation-tab__link--active) {
    opacity: 0.1;
  }

  &__pane {
    transition: z-index $base-animation-duration;
  }

  &__pane--active {
    @include fade-in();

    opacity: 1;
    z-index: 1;
  }

  &__pane:not(.navigation-tab__pane--active) {
    @include fade-out();

    opacity: 0;
    z-index: -1;
  }
}
