.customer-section {
  color: hsl(0, 0%, 100%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: map-get($font-sizes, 'sm');
    font-weight: map-get($font-weight, 'bolder');
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    @include responsive-customer-section();
  }
}
