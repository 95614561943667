.form-contact {
  font-weight: 800;

  &__label {
    display: block;
  }

  &__checkbox {
    --accent-color: hsl(214, 16%, 92%);

    font-size: map-get($font-sizes, 'xs');
    margin-top: 1rem;
    margin-right: 1rem;
    border: rem(1.5px) solid var(--accent-color);
    display: inline-block;

    span {
      padding: rem(16px 16px 14px);
      display: block;
    }

    input {
      display: none;
    }

    input:checked + span {
      background: var(--accent-color);
    }

    &:hover {
      background: var(--accent-color);
      cursor: pointer;
    }
  }

  &__form-actionable {
    display: flex;
    justify-content: center;
  }

  &__form-input {
    margin-bottom: 2rem;
  }

  &__button.btn {
    padding: rem(20px 32px 17px);

    @include media-breakpoint-down('xs') {
      width: 100%;
    }
  }

  .form-contact__input {
    margin-top: rem(8px);
    
    &:focus {
      border-bottom: 1px solid var(--color-brand-purple-heart);
    }
  }
}
